import React from 'react';
import './aboutUsPage.css';

const AboutUsPage = () => {
    return (
        <div className="content-container">
            <main className="main-content">
                <article>
                    <h1 className="main-heading">Hakkımızda</h1>

                    <section className="content-section">
                        <div className="regular-text">
                            <p>Ultimate sporu Türkiye’de ilk olarak İstanbul’da, başlangıçta yabancıların ağırlıkta olduğu
                                amatör bir ekip tarafından 2007 yılının başlarından itibaren oynanmaya başlamıştır.
                                Daha sonraki yıllarda üniversite ve üniversite mezunlarının kurduğu takımların sayısının
                                artması sebebiyle Türkiye'de Frizbi alanındaki gelişmeleri tek çatı altında toplamak ve
                                haberleşme kanallarını sadeleştirmek için 2011 yılında Oyunun Ruhu Derneği (ORD)
                                kurulmuştur. Ultimate Frizbi sporunu yaymak, tanıtmak, bu dalda eğitim çalışmaları ve spor müsabakaları düzenlemek, bu spor dalının ulusal ve uluslararası alanda gelişimine katkıda bulunmak amaçları ile varlığını sürdürmektedir.
                            </p>
                        </div>
                    </section>

                    <section className="content-section">
                        <h2 className="section-heading">Oyunun Ruhu Nedir</h2>
                        <div className='regular-text'>
                            <p>
                                Frizbi sporunun temelini oluşturur. Oyuncuların hakem görevi görmesi oyunun ruhu
                                sayesinde olur. Kısaca oyuncuları tarafsız olmaya, karşı takımla saygılı iletişim kurmaya,
                                pozitif tavırlara ve kuralları bilmeye yönlendiren bir sistemdir. Oyuncuların bilerek kuralları
                                ihlal etmemesi prensibine dayanır. Sportmenliği maçta işleyen, yazılı bir çerçeveye oturtur.
                                Oyunun Ruhu Çemberi: Oyuncular her maç sonrası bir araya gelerek, maçtaki pozisyonların
                                üzerinden geçer. Karşı takım tebrik edilir, iyi yapılan şeyler kutlanır, yapıcı geri bildirimler
                                iletilir.
                            </p>
                            <p>
                                Maç sonu iki takım da birbirini oyunun ruhu konusunda kurallarda belirtilen kriterlere ve puan
                                cetveline göre puanlar. Turnuva sonunda toplam puanlara göre bir “Oyunun Ruhu”
                                şampiyonu olur. Bu ödül, birincilik ile eş tutulan bir ödüldür.

                            </p>
                        </div>
                    </section>
                </article>
            </main>
        </div>
    );
};

export default AboutUsPage;;