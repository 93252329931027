import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TeamsPage.css";

const TeamsPage = () => {
    const [teams, setTeams] = useState([]);

    const getTeams = async () => {
        try {
            const response = await axios.get("https://api.oyununruhudernegi.org.tr/teams", {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                setTeams(response.data);
            } else {
                console.error("Error fetching teams");
            }
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        getTeams();
    }, []);

    return (
        <div className="homediv">
            <div className="teams-container">
                {teams.map((team, index) => (
                    <div key={index} className="team-card">
                        <div className="card-header">
                            <img src={team.logo} alt={team.name} className="team-image" />
                            <h2 className="team-name">{team.name}</h2>
                        </div>
                        <div className="card-description">
                            <p>{team.location}</p>
                            <p>
                                <a href={team.link} target="_blank" rel="noopener noreferrer">
                                    @{team.name}
                                </a>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TeamsPage;