import React, { useState, useEffect, useCallback, useRef } from 'react';
import './ImageCarousel.css';

const ImageCarousel = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = useCallback(() => {
        setCurrentSlide((prev) => (prev + 1) % images.length);
    }, [images.length]);

    const prevSlide = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
        intervalRef.current = setInterval(nextSlide, 5000);
    };

    const intervalRef = useRef();

    useEffect(() => {
        intervalRef.current = setInterval(nextSlide, 5000);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [nextSlide]);

    return (
        <div className="carousel-container">
            <div className="carousel">
                <button className="carousel-button prev" onClick={prevSlide}>&#8249;</button>
                <div 
                    className="carousel-track" 
                    style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                    {images.map((image, index) => (
                        <div className="carousel-slide" key={index}>
                            <img src={image.src} alt={image.alt} />
                        </div>
                    ))}
                </div>
                <button className="carousel-button next" onClick={() => {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    nextSlide();
                    intervalRef.current = setInterval(nextSlide, 5000);
                }}>&#8250;</button>
            </div>
            <div className="carousel-dots">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => {
                            if (intervalRef.current) {
                                clearInterval(intervalRef.current);
                            }
                            setCurrentSlide(index);
                            intervalRef.current = setInterval(nextSlide, 5000);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageCarousel; 