import React from 'react';
import './aboutUltimatePage.css';

const AboutUltimatePage = () => {
    return (
        <div className="content-container">
            <main className="main-content">
                <article>
                    <h1 className="main-heading">Ultimate Nedir</h1>



                    <section className="hero-section">
                        <p className="hero-text">
                            Frizbi (disk) ile oynanır
                        </p>
                        <p className="hero-text">
                            Futbol sahası büyüklüğünde çim alanda → 7 kişilik takımlar
                        </p>
                        <p className="hero-text">
                            5 kişilik takımlarla basket sahası büyüklüğünde parkede ya da plajda
                        </p>
                        <p className="hero-text">
                            Atış ve bolca koşu içeren bir takım sporudur.
                        </p>
                        <p className="hero-text">
                            Zeka, dürüstlük, güç ve çevikliğe dayanır.
                        </p>
                        <p className="hero-text">
                            Oyunun Ruhu (Spirit of the Game) felsefesi ile sportmenliği benimser.
                        </p>
                    </section>



                    <section className="content-section">
                        <h2 className="section-heading">Dünya'da Frizbi</h2>
                        <div className='regular-text'>
                            <p>
                                Ultimate Frizbi, uluslararası camiada World Flying Disc Federation (WFDF) tarafından temsil
                                edilmektedir, Federasyonun 108 üye ülkeden temsilci dernekleri bulunmaktadır.
                            </p>
                            <p>
                                WFDF, Uluslararası Olimpiyat Komitesi (IOC), Uluslararası Paralimpik Komitesi (IPC), FISU,
                                ARISF, GAISF, Uluslararası Master Oyunları Birliği (IMGA) ve Uluslararası Dünya Oyunları
                                Birliği (IWGA) tarafından tam olarak tanınan (full recognition) uluslararası bir federasyondur.
                            </p>
                        </div>
                    </section>



                    <section className="content-section">
                        <h2 className="section-heading">Nasıl Oynanır</h2>
                        <div className='regular-text'>
                            <p>
                                Takımlar sahanın karşılıklı iki ucundaki sayı bölgelerine yerleşir. Maça savunma başlayan
                                takım başlangıç atışı yapar ve diski, hücum yapan takımın tarafına atar. Oyun diskin düştüğü
                                yerden başlar. Hücum eden takımın amacı, diski yere düşürmeden paslaşarak ilerlemek ve
                                rakip sayı bölgesinde diski havada tutmaktır. Bu durumda sayı olur.
                            </p>
                            <p>
                                Savunma yapan takımın amacı ise araya girip diski almak, diskin yere düşmesini veya
                                sahadan çıkmasını sağlamaktır. Bu durumda oyun durmadan takım hücum sırası değişir ve
                                az önce savunmada başarılı olan takım sayıyı aynı şekilde rakip sayı alanında almaya
                                çalışır.
                            </p>
                            <p>
                                Diski tutan oyuncu diskle birlikte hareket edemez, pas vermek zorundadır.
                                Frizbi temassız bir oyundur ve rakibe yapılan müdahaleler faul olarak değerlendirilir.
                            </p>
                        </div>
                    </section>


                    <section className="content-section">
                        <h2 className="section-heading">Neden Frizbi</h2>
                        <div className='feature-heading'>
                            <p>Sporu Tabana Yaymak İçin Mükemmel Bir Araç:</p>
                        </div>
                        <div className="feature-grid">
                            <div className="feature-card">
                                <h3 className="feature-title">Erişilebilirlik</h3>
                                <p>Frizbi oynamak için gereken ekipman oldukça basit ve ucuzdur, bu da sporun herkes tarafından erişilebilir olmasını sağlar.</p>
                            </div>
                            <div className="feature-card">
                                <h3 className="feature-title">Kolay Öğrenme</h3>
                                <p>Temel kurallar ve oynanış kolayca öğrenilebilir, her yaştan ve seviyeden insanı spora çekebilir.</p>
                            </div>
                        </div>


                        <div className='feature-heading'>
                            <p>Sosyal Gelişim Projelerinde Kullanılabilir:</p>
                        </div>
                        <div className="feature-grid">

                            <div className="feature-card">
                                <h3 className="feature-title">Topluluk Ruhu</h3>
                                <p> Oyunun Ruhu (Spirit of the Game) ilkesi sayesinde oyuncular arasında saygı ve dürüstlük teşvik edilir, bu da sosyal projeler için ideal bir ortam yaratır.</p>
                            </div>
                            <div className="feature-card">
                                <h3 className="feature-title">İletişim ve İşbirliği</h3>
                                <p>Frizbi, iletişim ve işbirliği becerilerini geliştirir, bu da gençler için önemli kazanımlar sağlar.</p>
                            </div>
                        </div>


                        <div className='feature-heading'>
                            <p>Türkiye'nin Olimpik Potansiyeli:</p>
                        </div>
                        <div className="feature-grid">
                            <div className="feature-card">
                                <h3 className="feature-title">Yeni Bir Spor Dalı</h3>
                                <p>Yaklaşık 50 yıllık yeni bir spor olduğu için rekabet henüz derinleşmiş değil, Türkiye'nin bu alanda olimpik atletler yetiştirmesi mümkün.</p>
                            </div>
                            <div className="feature-card">
                                <h3 className="feature-title">Uluslararası Tanınırlık</h3>
                                <p>Dünya Frizbi Federasyonu (WFDF) ve diğer uluslararası kuruluşlar tarafından tanınan bir spor dalı, bu da uluslararası arenada yer almayı kolaylaştırır.</p>
                            </div>
                        </div>


                        <div className='feature-heading'>
                            <p>Yeni ve Dinamik Bir Spor:</p>
                        </div>
                        <div className="feature-grid">
                            <div className="feature-card">
                                <h3 className="feature-title">Gençlere Hitap Etmesi</h3>
                                <p>Ultimate Frizbi, genç nesiller arasında popüler bir spor dalıdır. Genç kitleyi Olimpiyatlara çekmek ve ilgilerini canlı tutmak için ideal bir seçenektir.</p>
                            </div>
                            <div className="feature-card">
                                <h3 className="feature-title">Enerjik ve Eğlenceli</h3>
                                <p>Hızlı tempolu, stratejik ve eğlenceli doğası ile seyirci ilgisini artırabilir, televizyon yayınları ve sosyal medya paylaşımlarında yüksek etkileşim sağlayabilir.</p>
                            </div>
                        </div>


                        <div className='feature-heading'>
                            <p>Toplumsal Katkı ve Entegrasyon:</p>
                        </div>
                        <div className="feature-grid">
                            <div className="feature-card">
                                <h3 className="feature-title">Kapsayıcılık</h3>
                                <p>Her yaştan ve cinsiyetten insanların bir arada oynayabileceği bir spor dalıdır.</p>
                            </div>
                            <div className="feature-card">
                                <h3 className="feature-title">Gönüllülük ve Organizasyon</h3>
                                <p>Turnuva ve etkinliklerin düzenlenmesi sırasında toplulukların bir araya gelmesini sağlar, gönüllülük bilincini artırır.</p>
                            </div>
                        </div>


                        <div className='feature-heading'>
                            <p>Oyunun Ruhu İlkesi:</p>
                        </div>
                        <div className="feature-grid">
                            <div className="feature-card">
                                <h3 className="feature-title">Fair Play ve Sportmenlik</h3>
                                <p>Ultimate Frizbi'nin temelinde yatan Oyunun Ruhu (Spirit of the Game) ilkesi, fair play ve sportmenlik anlayışını Olimpiyat ruhu ile örtüştürür.</p>
                            </div>
                            <div className="feature-card">
                                <h3 className="feature-title">Hakemsiz Oyun</h3>
                                <p>Oyuncuların hakemlik yapması, dürüstlük ve sorumluluk bilincini ön plana çıkarır, sporun etik değerlerini vurgular.</p>
                            </div>
                        </div>


                        <div className='feature-heading'>
                            <p>Toplumsal Fayda ve Gelişim:</p>
                        </div>
                        <div className="feature-grid">
                            <div className="feature-card">
                                <h3 className="feature-title">Sosyal Projeler</h3>
                                <p>Frizbi, sosyal gelişim projelerinde rahatlıkla kullanılabilir, topluluklar arasında dayanışma ve iletişimi güçlendirir.</p>
                            </div>
                            <div className="feature-card">
                                <h3 className="feature-title">Sağlıklı Yaşam</h3>
                                <p>Fiziksel aktiviteyi teşvik eder, gençler arasında sağlıklı yaşam alışkanlıklarının yaygınlaşmasına katkıda bulunur.</p>
                            </div>
                        </div>
                    </section>
                </article>
            </main>
        </div>
    );
};

export default AboutUltimatePage;