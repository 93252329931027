import React, {useState, useEffect} from "react";
import axios from "axios";
import "./TimelinePage.css";

const Dot = () => (
  <div className="timeline-dot-container">
    <div className="timeline-dot"></div>
  </div>
);

const TimelineItem = ({ title, description, image, date, position }) => (
  <div className={`timeline-item ${position === 'left' ? 'timeline-item-left' : 'timeline-item-right'}`}>

    {position !== 'left' && <Dot />}
    {position == 'left' && 
      <div className="timeline-image">
        {image && <img src={image} alt={title} className={`timeline-img ${position === 'left' ? 'ms-auto' : 'me-auto'}`} />}
      </div>}

    <div className={`timeline-content ${position === 'left' ? 'text-right' : 'text-left'}`}>
      <div className="timeline-year">{date}</div>
      <div className="timeline-title">{title}</div>
      
      <div className="timeline-text">{description}</div>
    </div>

    {position !== 'left' && 
      <div className="timeline-image">
        {image && <img src={image} alt={title} className={`timeline-img ${position === 'left' ? 'ms-auto' : 'me-auto'}`} />}
      </div>}
    {position === 'left' && <Dot />}
  </div>
);


const TimelinePage = () => {

  const [timelineItems, setTimelineItems] = useState([]);

  const getTimeline = async () => {
    try {
      console.log("Fetching timeline");
      const response = await axios.get("https://api.oyununruhudernegi.org.tr/timeline", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setTimelineItems(response.data);
      } else {
        console.error("Error fetching timeline");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTimeline();
  }, []);



  return (
    <div>
      <div className="timeline-container">
        <h1 className="timeline-header">Türkiye'de Ultimate Tarihi</h1>
        <div className="timeline-line"></div>
        {timelineItems.map((item, index) => (
          <TimelineItem
            key={index}
            date={item.date}
            title={item.title}
            description={item.description}
            image={item.image}
            position={index % 2 === 0 ? 'left' : 'right'}
          />
        ))}
      </div>
    </div>
  );
};


export default TimelinePage;