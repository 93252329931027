import React from 'react';
import { FaEnvelope, FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <span>© 2024 Oyunun Ruhu Derneği.</span>
      <div className="footer-icons">
        <a href="https://www.facebook.com/groups/389998417797403/" aria-label="Facebook">
          <FaFacebook />
        </a>
        <a href="http://twitter.com/OyununRuhu" aria-label="Twitter">
          <FaTwitter />
        </a>
        <a href="https://www.instagram.com/turkeyultimate/" aria-label="Instagram">
          <FaInstagram />
        </a>
        <a href="https://www.youtube.com/@frizbitr" aria-label="YouTube">
          <FaYoutube />
        </a>
        <a href="mailto:info@oyununruhudernegi.org.tr" aria-label="Email">
          <FaEnvelope />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
